/* Keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
    to {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
}

/* Keyframes for fade-out animation */
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
    to {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
}

/* Overlay for the modal, fixed position to cover the screen */
.modal-overlay {
    position: fixed;
    top: 400px;
    left: 40rem;
    right: 0px;
    bottom: 0px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3500;
    scale: 100%;
}

/* Container for the modal content, centered inside the overlay */
.modal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

/* Animation states for the modal content */
.modal-content.show {
    animation-name: fadeIn;
}

.modal-content.hide {
    animation-name: fadeOut;
}

/* Styles for the notepad image inside the modal */
.notepad-image {
    width: 800px;
    height: auto;
    z-index: 3501;
}

/* Styles for the about text inside the modal */
.about-text {
    position: absolute;
    top: 74px;
    left: 10.5px;
    width: 75%;
    height: 74%;
    color: var(--black);
    background-color: var(--white);
    padding: 5px;
    font-family: 'ms1', 'MS Sans Serif', sans-serif;
    font-weight: 600;
    font-size: 18px;
    overflow-y: auto;
    z-index: 3503;
}

.title {
    font-size: 20px;
    font-family: 'ms2', 'MS Sans Serif', sans-serif;
    font-weight: bold;
}
.tools {
    font-size: 14px;
    font-family: 'ms1', 'MS Sans Serif', sans-serif;
    font-style: italic;
}

.special-font {
    font-family: 'ms2', 'MS Sans Serif', sans-serif;
    font-weight: bold;
}

/* Close button */
.nclose-button {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 3700;
    padding: 9px;
}

/* Change color of close button on hover */
.nclose-button:hover {
    color: var(--red);
}

/* Media query for mobile screens */
@media (max-width: 940px) {
    .modal-overlay {
        position: fixed;
        top: 450px; 
        left: 445px; 
    }

    .about-text {
        top: 74px;
        left: 12px;
        font-size: 18px;
        width: 40%; 
        height: calc(50vh - 180px); 
    }
}

/* Media query for small screens */
@media (min-width: 941px) and (max-width: 1440px) {
    .modal-overlay {
        position: fixed;
        top: 100px; 
        left: 445px; 
    }

    .notepad-image {
        width: 550px;
        height: auto;
        z-index: 3501;
    }

    .about-text {
        top: 54px;
        left: 12px;
        font-size: 18px;
        width: 80%; 
        height: 70%; 
    }
}
