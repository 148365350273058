@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
    to {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
    to {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
}


/* Overlay for the terminal modal, fixed position to cover the screen */
.tmodal-overlay {
    position: fixed;
    top: 500px;
    left: 720px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3400;
    width: 10%;
    height: 10%;
}

/* Container for the terminal modal content, centered inside the overlay */
.tmodal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.tmodal-content.show {
    animation-name: fadeIn;
}

.tmodal-content.hide {
    animation-name: fadeOut;
}

.tnotepad-image {
    width: 800px;
    height: auto;
    z-index: 3401;
}

/* Typewriter container with relative positioning */
.typewriter-container {
    position: absolute;
    top: 27%;
    left: 3%;
    width: 90%;
    height: auto;
    color: var(--black);
    padding: 5px;
    font-family: 'ms1', 'MS Sans Serif', sans-serif;
    font-weight: 600;
    font-size: 18px;
    overflow-y: auto;
    z-index: 3403;
}

/* Close button */
.tclose-button {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 3404;
    padding: 9px;
}

/* Change color of close button on hover */
.tclose-button:hover {
    color: var(--red);
}

/* Media query for mobile screens */
@media (max-width: 940px) {
    .tmodal-overlay {
        position: fixed;
        top: 450px;
        left: 400px;
        padding-right: 0px;
        height: 0px;
    }

    
    .typewriter-container {
        position: absolute;
        top: 27%;
        left: 3%;
    }

    .close-button {
        position: absolute;
        left: 367px;
        top: 4px;
    }
}

/* Media query for small screens */
@media (min-width: 941px) and (max-width: 1440px) {
    .tmodal-overlay {
        position: fixed;
        top: 500px;
        left: 545px;
    }
}