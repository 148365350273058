/* Keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
    to {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
}

/* Keyframes for fade-out animation */
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
    to {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
}


/* Paint modal */
.pmodal-overlay {
    position: fixed;
    top: 0px;
    left: 750px;
    right: 0px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 100%;
    z-index: 3000;
    width: 10%;
}

.pmodal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.pmodal-content.show {
    animation-name: fadeIn;
}

.pmodal-content.hide {
    animation-name: fadeOut;
}

.pnotepad-image {
    width: 1200px;
    height: auto;
    z-index: 3001;
}

.pabout-text {
    position: absolute;
    top: 80px;
    left: 113px;
    width: 88.7%;
    height: 73.7%;
    color: #303030;
    background-color: var(--white);
    font-family: 'ms2', 'MS Sans Serif', sans-serif;
    font-size: 18px;
    z-index: 3002;
}

/* Close button */
.pclose-button {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 3003;
    padding: 9px;
}

.pclose-button:hover {
    color: var(--red);
}

@media (max-width: 940px) {
    .pmodal-overlay {
        position: fixed;
        top: 70px;
        left: 610px;
        bottom: unset;
    }
}

@media (min-width: 941px) and (max-width: 1440px) {
    .pmodal-overlay {
        position: fixed;
        top: 70px;
        left: 610px;
        bottom: unset;
    }

    .pnotepad-image {
        width: 775px;
        height: auto;
        z-index: 3001;
    }

    .pabout-text {
        position: absolute;
        top: 50px;
        left: 73px;
        width: 88.7%;
        height: 73.7%;
        color: #303030;
        background-color: var(--white);
        font-family: 'ms2', 'MS Sans Serif', sans-serif;
        font-size: 18px;
        z-index: 3002;
    }
}