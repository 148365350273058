:root {
  --darkgray: #818181;
  --lightgray: #c3c3c3;
  --white: #fdffff;
  --teal: #008080;
  --blue: #010081;
  --black: #000000;
  --pink: #ff0081;
}

@font-face {
  font-family: 'ms1';
  src: url('/public/fonts/ms-sans-serif-1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ms2';
  src: url('/public/fonts/ms-sans-serif-4.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'ms1', 'MS Sans Serif', sans-serif;
  background-color: var(--teal);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 1rem;
}

button {
  background-color: var(--darkgray);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--black);
  border-bottom: 2px solid var(--black);
  color: var(--black);
  font-weight: bold;
  padding: .7rem 1.5rem;
  border-radius: 0px;
  cursor: pointer;
  font-family: 'ms1', 'MS Sans Serif', sans-serif;
}

@media screen and (max-width: 940px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}
