@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
    to {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
    to {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
}

/* Overlay for the sound modal, fixed position to cover the screen */
.smodal-overlay {
    position: fixed;
    top: 450px;
    left: 570px;
    right: 0px;
    bottom: 0px;
    width: 450px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 100%;
    z-index: 10002;
}

/* Container for the sound modal content, centered inside the overlay */
.smodal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.smodal-content.show {
    animation-name: fadeIn;
}

.smodal-content.hide {
    animation-name: fadeOut;
}

.snotepad-image {
    width: 450px;
    height: auto;
    z-index: 10003;
}

/* Close button */
.ssclose-button {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 10004;
    padding: 9px;
}

/* Change color of close button on hover */
.close-button:hover {
    color: var(--red);
}

/* Stop button */
.sclose-button {
    position: absolute;
    top: 197.5px;
    right: 105px;
    width: 75px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 10004;
    padding: 9px;
}

/* Change color of stop button on hover */
.sclose-button:hover {
    color: var(--red);
}

/* Play button */
.play-button {
    position: absolute;
    top: 197.5px;
    right: 188px;
    width: 75px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 10004;
    padding: 9px;
}

/* Change color of play button on hover */
.play-button:hover {
    color: var(--red);
}

/* Media query for mobile screens */
@media (max-width: 940px) {
    .smodal-overlay {
        position: fixed;
        top: 320px;
        left: -45px;
    }

    .snotepad-image {
        width: 75%;
        height: auto;
        z-index: 10003;
    }

    .ssclose-button {
        position: absolute;
        left: 367px;
        top: 4px;
    }

    .sclose-button {
        position: absolute;
        width: 50px;
        top: 144px;
        left: 264px;
    }

    .play-button {
        position: absolute;
        width: 50px;
        top: 144px;
        left: 200px;
    }
}

/* Media query for small screens */
@media (min-width: 941px) and (max-width: 1440px) {
    .smodal-overlay {
        position: fixed;
        top: 400px;
        left: 245px;
    }

    .snotepad-image {
        width: 450px;
        height: auto;
        z-index: 10003;
    }

    .sabout-text {
        top: 54px;
        left: 12px;
        font-size: 18px;
        width: 80%;
        height: 70%;
    }
}