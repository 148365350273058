@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }

    to {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }

    to {
        opacity: 0;
        transform: scale(0.93);
        filter: hue-rotate(180deg);
    }
}


/* Overlay for the terminal modal, fixed position to cover the screen */
.fmodal-overlay {
    position: fixed;
    top: -300px;
    ;
    left: 720px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3400;
    width: 10%;
}

/* Container for the terminal modal content, centered inside the overlay */
.fmodal-content {
    position: absolute;
    top: 500px;
    left: -560px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fmodal-content.show {
    animation-name: fadeIn;
}

.fmodal-content.hide {
    animation-name: fadeOut;
}

.fnotepad-image {
    width: 800px;
    height: auto;
    z-index: 3401;
}

.folder-items {
    position: absolute;
    left: 00px;
    z-index: 3401;
}

.folder-lists {
    position: relative;
    width: 400px;
    
}

.folder-items-img {
    position: relative;
    
    width: 120px;
}

.folder-items-text {
    position: relative;
    top: -40px;
    left: -20px;
    color: var(--black);
    font-size: 18px;
}

.folder-items-img:hover {
    cursor: pointer;
}



/* Close button */
.fclose-button {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--black);
    font-size: 2px;
    outline: none;
    z-index: 3404;
    padding: 9px;
}

/* Change color of close button on hover */
.fclose-button:hover {
    color: var(--red);
}

/* Media query for mobile screens */
@media (max-width: 940px) {
    .fmodal-overlay {
        display: none;
    }

}

/* Media query for small screens */
@media (min-width: 941px) and (max-width: 1440px) {
    .fmodal-overlay {
        position: fixed;
        top: -240px;
        left: 715px;
    }


    .fnotepad-image {
        width: 600px;
        height: auto;
        z-index: 3401;
    }

    .folder-items{
        position: absolute;
        top: 150px;
    }

}